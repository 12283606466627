import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';

export default () => (
  <Layout isRoot={true}>
    <section 
      className="py-20 md:py-40"
      style={{
        backgroundColor: '#ddf1fd',
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2365a4df' fill-opacity='0.34' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`
      }}
    >
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            PDF Table Util
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Extract tables from PDF documents in the web browser.
          </p>
          <p className="mt-8 md:mt-12">
            <Button size="lg" onClick={() => {
              window.location = '/app';
            }}>Try Now!</Button>
          </p>
          { /* <p className="mt-4 text-gray-600"></p> */ }
        </div>
        <div className="mt-10 lg:w-1/2 lg:mt-0">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Main Features</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <div class="flex justify-center mb-6">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width="47px" height="61px" viewBox="0 0 98.92 122.88" enable-background="new 0 0 98.92 122.88"><g><path style={{fillRule:'evenodd',clipRule:'evenodd'}} class="st0" d="M78.77,0c-3.29,39.36-48.34,13.5-65.45,46.28c-2.42,5.34-4.03,10.29-4.8,14.84c-1.26,1.55-2.42,3.22-3.45,5 c-5.22,9.05-6.28,19.35-3.78,28.71c2.51,9.35,8.58,17.75,17.63,22.97c9.05,5.22,19.35,6.28,28.71,3.78 c9.35-2.51,17.75-8.58,22.97-17.63c2.31-4,3.8-8.24,4.54-12.54c5.32-3.1,10.02-7.48,13.62-13.28c6.08-14.12-5.01-25.38,10.17-35.32 c-9.38-4.72-15.1,0.16-20.58,9.7c-5.93,12.88-28.14-2.37-7.32-10.29C88.92,35.42,87.29,14.45,78.77,0L78.77,0L78.77,0z M37.02,84.91c0.11,0,0.23,0,0.34,0.01l13.03-9.76c1.51-1.13,3.66-0.83,4.79,0.68c1.13,1.51,0.83,3.66-0.68,4.79L41.6,90.3 c-0.36,2.21-2.27,3.9-4.58,3.9c-2.56,0-4.64-2.08-4.64-4.64c0-0.49,0.08-0.96,0.22-1.41L19.33,77.89 c-0.84-0.65-0.99-1.85-0.35-2.69c0.65-0.84,1.85-0.99,2.69-0.35l13.5,10.44C35.74,85.05,36.36,84.91,37.02,84.91L37.02,84.91z M53.96,57.11c16.06,9.27,21.08,28,11.8,44.06c-9.27,16.06-28,21.08-44.06,11.81c-16.06-9.27-21.08-28-11.8-44.06 C19.18,52.86,37.9,47.84,53.96,57.11L53.96,57.11z"/></g></svg>
              </div>
              <p className="font-semibold text-xl">Speed</p>
              <p className="mt-4">
                PDF document processing can take seconds.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <div class="flex justify-center mb-6">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 104.69 122.88" width="52px" height="61px" enable-background="new 0 0 104.69 122.88"><g><path d="M31.54,86.95c-1.74,0-3.16-1.43-3.16-3.19c0-1.76,1.41-3.19,3.16-3.19h20.5c1.74,0,3.16,1.43,3.16,3.19 c0,1.76-1.41,3.19-3.16,3.19H31.54L31.54,86.95z M31.54,42.27c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,42.27z M66.57,108.66c-1.36-1.08-1.59-3.06-0.5-4.42 c1.08-1.36,3.06-1.59,4.42-0.5l9.57,7.59l18.21-22.27c1.1-1.35,3.09-1.54,4.43-0.44c1.35,1.1,1.54,3.09,0.44,4.43l-20.17,24.67l0,0 c-1.09,1.33-3.04,1.54-4.39,0.47L66.57,108.66L66.57,108.66z M56.85,116.58c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15 H7.33c-2.02,0-3.85-0.82-5.18-2.15C0.82,119.4,0,117.57,0,115.55V7.33c0-2.02,0.82-3.85,2.15-5.18C3.48,0.82,5.31,0,7.33,0h90.02 c2.02,0,3.85,0.82,5.18,2.15c1.33,1.33,2.15,3.16,2.15,5.18V72.6c0,1.74-1.41,3.15-3.15,3.15c-1.74,0-3.15-1.41-3.15-3.15V7.33 c0-0.28-0.12-0.54-0.3-0.73c-0.19-0.19-0.45-0.3-0.73-0.3H7.33c-0.28,0-0.54,0.12-0.73,0.3C6.42,6.8,6.3,7.05,6.3,7.33v108.21 c0,0.28,0.12,0.54,0.3,0.73c0.19,0.19,0.45,0.3,0.73,0.3H56.85L56.85,116.58z M31.54,64.59c-1.74,0-3.15-1.41-3.15-3.15 c0-1.74,1.41-3.15,3.15-3.15h41.61c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,64.59z"/></g></svg>
              </div>
              <p className="font-semibold text-xl">Scanned Documents</p>
              <p className="mt-4">
                 Pictures of tables in PDF documents and scanned documents are supported.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <div class="flex justify-center mb-6">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width="55px" height="61px" viewBox="0 0 111.811 122.88" enable-background="new 0 0 111.811 122.88"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M55.713,0c20.848,13.215,39.682,19.467,55.846,17.989 c2.823,57.098-18.263,90.818-55.63,104.891C19.844,109.708-1.5,77.439,0.083,17.123C19.058,18.116,37.674,14.014,55.713,0L55.713,0 z M33.784,66.775c-1.18-1.01-1.318-2.786-0.309-3.967c1.011-1.181,2.787-1.318,3.967-0.309l11.494,9.875l25.18-27.684 c1.047-1.15,2.828-1.234,3.979-0.188c1.149,1.046,1.233,2.827,0.187,3.978L51.262,78.188l-0.002-0.002 c-1.02,1.121-2.751,1.236-3.91,0.244L33.784,66.775L33.784,66.775z M55.735,7.055c18.454,11.697,35.126,17.232,49.434,15.923 c2.498,50.541-16.166,80.39-49.241,92.846C23.986,104.165,5.091,75.603,6.493,22.211C23.29,23.091,39.768,19.46,55.735,7.055 L55.735,7.055z"/></g></svg>
              </div>
              <p className="font-semibold text-xl">Privacy</p>
              <p className="mt-4">
                No data is sent to the cloud. No tracking software is used. See the <a href="/privacy">Privacy Policy</a>.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <section id="demo" className="py-20 bg-gray-200">
      <div className="container mx-auto text-center flex flex-col items-center justify-center">
        <h2 className="text-3xl lg:text-5xl font-semibold mb-6">Demo</h2>
        <video preload="metadata" controls width="640" height="480">
          <source src="/vid/demo.webm" type="video/webm"/>
          <source src="/vid/demo.mp4" type="video/mp4"/>
        </video>
      </div>
    </section>
    <section id="privacy" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Privacy</h2>
        <p className="mt-6">
          No data is sent to the cloud. No tracking software is used. See the <a href="/privacy">Privacy Policy</a>.
        </p>
      </div>
    </section>
    {/*<SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Available Everywhere</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            PDF Table Utils has been built to support all major web browsers and require no additional software.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />*/}
    {/*<SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Integratable
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            PDF Table Util has been built with a focus on being 
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />*/}
    {/*<SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Available in Office
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Work is underway to make PDF Table Util available as a Microsoft Office and Google Docs add-in.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />*/}
    {/*<section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
      </section>*/}
    <section className="py-20 bg-gray-200 text-center">
      <h3 className="text-5xl font-semibold">Have Questions?</h3>
      {/*<p className="mt-8 text-xl font-light">
        FeedbackInterested in PDF Table Util?
        Quis lectus nulla at volutpat diam ut. Enim lobortis scelerisque fermentum dui faucibus in.
      </p>*/}
      <p className="mt-8">
        <Button size="xl" onClick={() => {
          window.location = 'mailto:pdftableutil@gmail.com'
        }}>Equire Now</Button>
      </p>
    </section>
  </Layout>
);
